body {
    background-color: #FAFAFA;
}

.logo {
    max-width: 200px;
    margin: 10px;
}

.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    margin: 0 auto;
    width: 100%;
    max-width: 940px;
}

@media only screen and (max-width: 940px) {
    .container {
        grid-template-columns: 1fr;
        max-width: 430px;
        min-width: 450px;
    }
}