.social {
    color: inherit;
    width: 16px;
    margin: 4px 10px;
    transition: linear .2s;
}

.social:hover {
    color: #228bc7;
    fill: #228bc7;
}