.card {
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "image name" "image contact"; */
    text-align: center;
    background: #edebe7;
    padding: 10px;
    margin: 10px;
    border-radius: 2px;
    width: 410px;
    height: 180px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    transition: box-shadow linear 0.5s;
    --marginTop: 10px;
}

.card:hover {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 28%), 0 2px 10px 0 rgb(0 0 0 / 24%);
}

.name {
    margin-top: 33px;
    color: #0E0E0E;
    grid-area: "name";
    font-family: 'Courier New', Courier, monospace;
    font-weight: bolder;
    font-size: 16pt;
}

.subtitle {
    margin-top: var(--marginTop);
    color: #3d3d3d;
    font-family: 'Courier New', Courier, monospace;
    font-size: 12pt;
}

.contact {
    grid-area: "contact";
    margin-top: var(--marginTop);
}

.website {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    /* text-transform: uppercase; */
    text-decoration: none;
    /* font-style: bold; */
    margin-top: 10px;
    color: #225cc7;
}

.image {
    grid-area: "image";
    max-width: 180px;
}

.icons {
    margin-top: var(--marginTop);
}


/* 
body {
    margin: 0 auto;
    padding: 0;
    background: #222;
  }
  
  .left {
    left: 25px;
  }
  
  .right {
    right: 25px;
  }
  
  .center {
    text-align: center;
  }
  
  .bottom {
    position: absolute;
    bottom: 25px;
  }
  
  #gradient {
    background: #999955;
    background-image: linear-gradient(#DAB046 20%, #D73B25 20%, #D73B25 40%, #C71B25 40%, #C71B25 60%, #961A39 60%, #961A39 80%, #601035 80%);
    margin: 0 auto;
    margin-top: 100px;
    width: 100%;
    height: 150px;
  }
  
  #gradient:after {
    content: "";
    position: absolute;
    background: #E9E2D0;
    left: 50%;
    margin-top: -67.5px;
    margin-left: -270px;
    padding-left: 20px;
    border-radius: 5px;
    width: 520px;
    height: 275px;
    z-index: -1;
  }
  
  #card {
    position: absolute;
    width: 450px;
    height: 225px;
    padding: 25px;
    padding-top: 0;
    padding-bottom: 0;
    left: 50%;
    top: 67.5px;
    margin-left: -250px;
    background: #E9E2D0;
    box-shadow: -20px 0 35px -25px black, 20px 0 35px -25px black;
    z-index: 5;
  }
  
  #card img {
    width: 150px;
    float: left;
    border-radius: 5px;
    margin-right: 20px;
    -webkit-filter: sepia(1);
    -moz-filter: sepia(1);
    filter: sepia(1);
  }
  
  #card h2 {
    font-family: courier;
    color: #333;
    margin: 0 auto;
    padding: 0;
    font-size: 15pt;
  }
  
  #card p {
    font-family: courier;
    color: #555;
    font-size: 13px;
  }
  
  #card span {
    font-family: courier;
  }*/